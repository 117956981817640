.tabNav_items a{
    color: #000;
    outline: 1px solid #000;
    display: block;
    border-radius: 4px;
}
.tabNav_items a:hover{
    background-color: var(--primaryLight);
}
.tabNav_items a.active{
    color: #fff;
    outline: none;
    background-color: var(--primaryColor);
}