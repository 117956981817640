.pageNumber {
    border: 1px solid var(--color3);
    border-radius: 100%;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selectedPageNumber {
    background-color: var(--color3);
    color: #fff;
}

.lastPage {
    border: none;
    cursor: not-allowed;
    border-radius: 100%;
}
