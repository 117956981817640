@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@layer components{
  .container_xxl{
    @apply max-w-[1536px] mx-0 px-8
  }
  .row{
    @apply flex flex-wrap
  }

  .label{
    @apply text-xl lato mb-2
  }
  .input{
    @apply border-solid border-2 border-gray-300 py-2 px-2 rounded w-full placeholder:italic placeholder:text-sm
  }
  .input3{
    @apply border-solid border-2 border-gray-300 py-2 px-2 rounded w-1/2 placeholder:italic placeholder:text-sm
  }
  .input2{
    @apply border-solid border border-gray-300 py-2 px-2 rounded w-full placeholder:italic placeholder:text-sm
  }
  .btn3{
    @apply flex items-center px-8 py-2 space-x-2 rounded bg-[color:var(--primaryBlue)] font-semibold
  }
  .heading{
    @apply text-4xl font-semibold py-5 text-gray-700
  }
  .headingBorder__b{
    @apply border-b border-gray-600
  }
  .mandatory {
    @apply  text-red-800
  }

}
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato', sans-serif;
  font-family: 'Open Sans', sans-serif;
}

:root {
  --primaryColor: #272055;
  --color1: #272055;
  --color2: #c1c3d6;
  --color3: #f5ba45;
  --color4: #fffffb;
  --primaryLight: #453c7eaa;
  --primaryBlue:#0084b5;
  --primaryYellow:#fbce01;
  --primaryRed:#8c002f;
  --primaryWhite:#ffffff;
  --primaryBlack:#020617;
}

.lato {
  font-family: 'Lato', sans-serif;
  font-family: 'Open Sans', sans-serif;
}

.errorMessage{
  display: none;
}
.input:invalid[focused="true"]{
  border: 1px solid #b91c1b;
}
.input:invalid[focused="true"] ~ small{
  display: block;
}

.pageNumber{
  border: 1px solid var(--primaryColor);
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectedPageNumber{
  background-color: var(--primaryColor);
  color: #fff;
}
.lastPage{
  border: none;
  cursor: not-allowed;
  border-radius: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
